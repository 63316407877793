<!--
 *@author huhongyun
 *@date 2021/10/13
 *@component 操作弹框组件
-->
<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :class="`ykc-operation-dialog ykc-operation-dialog-${type}`"
    ref="operationDialog"
    :before-close="beforeClose"
    :fullscreen="fullscreen || defaultFullScreen"
    :title="title"
    :visible.sync="show"
    :width="customerWidth || defaultWidth"
    :top="top"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :lock-scroll="lockScroll"
    :custom-class="localCustomClass"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :show-close="showClose"
    :center="center"
    :destroy-on-close="destroyOnClose">
    <template v-if="customTitle" slot="title">
      <slot name="title"></slot>
    </template>
    <!-- 用户传入的 内容 (content) slot -->
    <template class="ykc-operation-dialog-content" v-if="$slots.content">
      <div v-if="hasContentBorder" class="operation-dialog-scrollbar-view-box">
        <div class="dialog-operation-view-border">
          <el-scrollbar tab="div" view-class="operation-dialog-scrollbar-wrapper">
            <slot name="content"></slot>
          </el-scrollbar>
        </div>
      </div>
      <el-scrollbar
        v-else
        tag="div"
        wrap-class="operation-dialog-scrollbar-wrapper"
        view-class="operation-dialog-scrollbar-view-box">
        <slot name="content"></slot>
      </el-scrollbar>
    </template>
    <!--通用类型的 footer 部分-->
    <template v-if="showFooter && type === 'normal'" slot="footer" class="dialog-footer">
      <el-button
        v-if="showCancelButton"
        :style="footerBtnStyle"
        class="cancel-btn"
        @click="onCancel">
        {{ cancelBtnTxt || '取 消' }}
      </el-button>
      <el-button
        class="confirm-btn"
        :style="footerBtnStyle"
        type="primary"
        :loading="confirmLoading"
        @click="onConfirm">
        {{ confirmBtnTxt || '确 定' }}
      </el-button>
    </template>
    <!-- 报表类型的 footer 部分 -->
    <template v-if="showFooter && type === 'report'" slot="footer" class="dialog-footer">
      <el-button
        v-if="showCancelButton"
        :style="footerBtnStyle"
        class="cancel-btn"
        @click="onCancel">
        {{ cancelBtnTxt || '取 消' }}
      </el-button>
      <el-button class="confirm-btn" :style="footerBtnStyle" type="primary" @click="onConfirm">
        {{ confirmBtnTxt || '确认导入标签' }}
      </el-button>
    </template>
    <!-- 自定义 footer slot -->
    <template v-if="showFooter && type === 'custom'" slot="footer">
      <slot name="custom-footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'YkcOperationDialog',
    props: {
      // 自定义标题栏
      customTitle: {
        type: Boolean,
        default: false,
      },
      // 是否有边框
      hasContentBorder: {
        type: Boolean,
        default: false,
      },
      // 是否为 js 版本
      isJSVersion: {
        type: Boolean,
        default: false,
      },
      confirmLoading: {
        type: Boolean,
        default: false,
      },
      // footer btn 样式
      footerBtnStyle: [String, Object],
      // 是否展示弹窗
      show: Boolean,
      // 自定义全屏图标名称
      customFullScreenIcon: {
        type: String,
        default: '',
      },
      // 是否展示 footer 部分, 默认展示
      showFooter: {
        type: Boolean,
        default: true,
      },
      // 是否展示全屏图标
      showFullScreenIcon: {
        type: Boolean,
        default: false,
      },
      // 是否全屏展示
      fullscreen: {
        type: Boolean,
        default: false,
      },
      // 尺寸
      size: {
        type: String,
        default: 'medium',
        validator(val) {
          return ['medium', 'large'].includes(val);
        },
      },
      // 显示取消按钮
      showCancelButton: {
        type: Boolean,
        default: true,
      },
      // 类型， 默认一般弹窗
      type: {
        type: String,
        default: 'normal',
        validator(val) {
          return ['normal', 'report', 'custom'].includes(val);
        },
      },
      // 标题
      title: {
        type: String,
        default: '默认标题',
      },
      // 自定义宽度
      customerWidth: {
        type: String,
      },
      //  Dialog CSS 中的 margin-top 值
      top: {
        type: String,
        default: '30vh',
      },
      // 是否需要遮罩层
      modal: {
        type: Boolean,
        default: true,
      },
      // 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素
      modalAppendToBody: {
        type: Boolean,
        default: true,
      },
      // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
      appendToBody: {
        type: Boolean,
        default: true,
      },
      // 是否在 Dialog 出现时将 body 滚动锁定
      lockScroll: {
        type: Boolean,
        default: true,
      },
      // Dialog 的自定义类名
      customClass: {
        type: String,
        default: '',
      },
      // 是否可以通过点击 modal 关闭 Dialog
      closeOnClickModal: {
        type: Boolean,
        default: false,
      },
      // 是否可以通过按下 ESC 关闭 Dialog
      closeOnPressEscape: {
        type: Boolean,
        default: false,
      },
      // 是否显示关闭按钮
      showClose: {
        type: Boolean,
        default: true,
      },
      // 是否对头部和底部采用居中布局
      center: {
        type: Boolean,
        default: false,
      },
      // 关闭时销毁 Dialog 中的元素
      destroyOnClose: {
        type: Boolean,
        default: false,
      },
      //  取消文本
      cancelBtnTxt: String,
      // 确认文本
      confirmBtnTxt: String,
      // 点击取消的钩子函数
      beforeCancel: Function,
      // 点击确认调用的回调函数
      beforeConfirm: Function,
      // 点击关闭按钮时
      onCLose: Function,
      onClose: Function,
    },
    computed: {
      defaultWidth() {
        if (this.size === 'medium') {
          return '500px';
        }
        if (this.size === 'large') {
          return '800px';
        }
        return '30%';
      },
      localCustomClass() {
        if (this.customClass) {
          return `ykc-dialog ykc-dialog-${this.type} ${this.customClass || ''}`;
        }
        return `ykc-dialog ykc-dialog-${this.type}`;
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.$emit('update:show', true);
          this.$emit('open-dialog');
        } else {
          this.$emit('update:show', false);
          this.$emit('close-dialog');
        }
      },
    },
    data() {
      return {
        showDialog: true,
        defaultFullScreen: false,
        closeIcon: '',
      };
    },
    methods: {
      beforeClose() {
        // 如果 onClose 和 onCLose 都存在，且 onClose 是函数
        if (this.onClose && this.onCLose && typeof this.onClose === 'function') {
          this.onClose(this.hide);
          // 如果 onCLose 不存在，但 onClose 存在，且 onClose 是函数
        } else if (!this.onCLose && this.onClose && typeof this.onClose === 'function') {
          this.onClose(this.hide);
          // 如果 onCLose 存在 但 onClose 不存在， 且 onCLose 是函数
        } else if (this.onCLose && !this.onClose && typeof this.onCLose === 'function') {
          this.onCLose(this.hide);
        } else {
          this.hide();
        }
      },
      /**
       * 插入 [全屏图标]
       * */
      insertFullScreenIcon() {
        const el = this.$el.querySelector('.el-dialog');
        const $header = el.querySelector('.el-dialog__header');
        if ($header.querySelector('.el-icon-full-screen')) {
          return;
        }
        const fullScreenEl = document.createElement('i');
        fullScreenEl.setAttribute('class', `el-icon ${this.customFullScreenIcon}`);
        $header.appendChild(fullScreenEl);
        fullScreenEl.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          this.toggleFullScreen();
        });
      },
      /**
       * 用户点击了取消，调用用户传入的取消钩子函数
       * */
      onCancel() {
        if (typeof this.beforeCancel === 'function') {
          this.beforeCancel(this.hide);
        } else {
          this.hide();
        }
      },
      /**
       * 用户点击了确认，直接调用用户传入的确认钩子函数
       * */
      onConfirm() {
        if (typeof this.beforeConfirm === 'function' && this.widget) {
          this.beforeConfirm(this.hide, this);
        } else if (typeof this.beforeConfirm === 'function') {
          this.beforeConfirm(this.hide);
        } else {
          this.hide();
        }
      },
      /**
       * 隐藏弹出函数
       * */
      hide(cancel) {
        if (cancel !== false) {
          this.$emit('update:show', false);
          this.$emit('close-dialog');
          if (this.isJSVersion) {
            this.destroyInstance();
          }
        }
      },
      /**
       * 手动销毁实例和DOM节点
       * */
      destroyInstance() {
        this.$destroy();
        if (this.$el && this.$el.parentNode) {
          this.$el.parentNode.removeChild(this.$el);
        }
      },
      /**
       * 切换全屏 -- 只切换默认值
       * */
      toggleFullScreen() {
        this.defaultFullScreen = !this.defaultFullScreen;
      },
    },
    mounted() {
      if (this.showFullScreenIcon) {
        this.insertFullScreenIcon();
      }
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
